import React from 'react';
import { graphql, PageProps } from 'gatsby';

// components
import { Layout, Seo, PostCard, Section, Archive } from '@/components';

type PageContext = {
  limit: number;
  skip: number;
  numPages: number;
  currentPage: number;
  linkBase: string;
  pageTitle: string;
};

/**
 * The template for the main archive page
 */
const ArchivePage = ({
  data,
  pageContext,
}: PageProps<Queries.MainArchiveQuery, PageContext>) => {
  const { currentPage, numPages, linkBase, pageTitle } = pageContext;
  const posts = data.allWpPost.edges;
  const content = posts.map(({ node }) => (
    <Section key={node.id}>
      <PostCard post={node} />
    </Section>
  ));

  return (
    <Layout>
      <Seo title={`${pageTitle} | Bringing Glory`} />
      <Archive
        currentPage={currentPage}
        numPages={numPages}
        linkBase={linkBase}
        pageTitle={pageTitle}
      >
        {content}
      </Archive>
    </Layout>
  );
};

export default ArchivePage;

// page query
export const archiveQuery = graphql`
  query MainArchive($skip: Int!, $limit: Int!) {
    allWpPost(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }) {
      edges {
        node {
          uri
          title
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, width: 1700)
                }
              }
            }
          }
          date
          excerpt
          id
        }
      }
    }
  }
`;
